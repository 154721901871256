import React from "react";
import { Link } from "react-router-dom";

export const Image = (props) => {
  return (
    <Link to={props.redirect}>
      <div className="portfolio-item">
        <img src={props.smallImage} alt={props.title} />
        <div className="hover-bg">
          <h3>{props.title}</h3>
          <div className="hline"></div>
        </div>
      </div>
    </Link>
  );
};