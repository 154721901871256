// src/components/navigation/Navigation.jsx

import React from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();

  return (
    <Navbar
      sticky="top"
      style={{ backgroundColor: "white" }}
      id="menu"
      bg="light"            // Sets the background color to Bootstrap's light color (white)
      variant="dark"          // Sets the text color to dark for better contrast
      expand="lg"             // Makes the navbar responsive, but since toggle is removed, it remains expanded
      fixed="top"             // Fixes the navbar to the top of the viewport
    >
      <Container>
        <Navbar.Brand
          as={Link}
          to="/"
          className="page-scroll small-font text-dark"
        >
          Kingsparrow
        </Navbar.Brand>

        {location.pathname === "/" && (
          <Nav className="ml-auto d-flex align-items-center p-5">
            {/* Features Button */}
            <Button
              as={HashLink}
              smooth
              to="#features"
              variant="outline-dark"
              className="me-3 mb-2 mb-lg-0 fs-4 fw-bold text-dark"
            >
              Features
            </Button>

            {/* About Button */}
            <Button
              as={HashLink}
              smooth
              to="#about"
              variant="outline-dark"
              className="me-3 mb-2 mb-lg-0 fs-4 fw-bold text-dark"
            >
              About
            </Button>

            {/* Services Button */}
            <Button
              as={HashLink}
              smooth
              to="#services"
              variant="outline-dark"
              className="me-3 mb-2 mb-lg-0 fs-4 fw-bold text-dark"
            >
              Services
            </Button>

            {/* Gallery Button */}
            <Button
              as={HashLink}
              smooth
              to="#portfolio"
              variant="outline-dark"
              className="me-3 mb-2 mb-lg-0 fs-4 fw-bold text-dark"
            >
              Gallery
            </Button>

            {/* Testimonials Button */}
            <Button
              as={HashLink}
              smooth
              to="#testimonials"
              variant="outline-dark"
              className="me-3 mb-2 mb-lg-0 fs-4 fw-bold text-dark"
            >
              Testimonials
            </Button>

            {/* Team Button */}
            <Button
              as={HashLink}
              smooth
              to="#team"
              variant="outline-dark"
              className="me-3 mb-2 mb-lg-0 fs-4 fw-bold text-dark"
            >
              Team
            </Button>

            {/* Contact Button */}
            <Button
              as={HashLink}
              smooth
              to="#contact"
              variant="outline-dark"
              className="fs-4 fw-bold text-dark"
            >
              Contact
            </Button>
          </Nav>
        )}
      </Container>
    </Navbar>
  );
};

export default Navigation;