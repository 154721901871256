import React from 'react';
import { Navbar, Nav, Container, Button, Row, Col, Card, Image } from 'react-bootstrap';
import Navigation from '../navigation';

const PointOfSale = () => {
  return (
    <div style={{ marginTop: "100px" }}>
      {/* Hero Section */}
      <section className="bg-light py-5">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1 className="text-primary">Revolutionize Your Business with POS</h1>
              <p className="text-muted">
                Streamline your sales, manage inventory, and enhance customer experience with our cutting-edge Point of Sale system.
              </p>
            </Col>
            <Col md={6} className="text-center">
              <Image src="https://source.unsplash.com/featured/?pos,retail" alt="POS System" fluid />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Features Section */}
      <section id="features" className="py-5">
        <Container>
          <h2 className="text-center mb-4 text-primary">Features</h2>
          <Row>
            <Col md={4} className="mb-4">
              <Card className="h-100 text-center">
                <Card.Img variant="top" src="https://source.unsplash.com/featured/?inventory" alt="Inventory Management" />
                <Card.Body>
                  <Card.Title>Inventory Management</Card.Title>
                  <Card.Text>
                    Keep track of your stock levels, manage suppliers, and automate reordering.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 text-center">
                <Card.Img variant="top" src="https://source.unsplash.com/featured/?analytics" alt="Sales Analytics" />
                <Card.Body>
                  <Card.Title>Sales Analytics</Card.Title>
                  <Card.Text>
                    Gain insights into your sales trends, customer behavior, and business performance.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 text-center">
                <Card.Img variant="top" src="https://source.unsplash.com/featured/?customer" alt="Customer Management" />
                <Card.Body>
                  <Card.Title>Customer Management</Card.Title>
                  <Card.Text>
                    Manage customer information, track purchase history, and enhance loyalty programs.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <Card className="h-100 text-center">
                <Card.Img variant="top" src="https://source.unsplash.com/featured/?payment" alt="Payment Processing" />
                <Card.Body>
                  <Card.Title>Payment Processing</Card.Title>
                  <Card.Text>
                    Accept multiple forms of payment including credit cards, checks, and mobile payments.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 text-center">
                <Card.Img variant="top" src="https://source.unsplash.com/featured/?employee" alt="Employee Management" />
                <Card.Body>
                  <Card.Title>Employee Management</Card.Title>
                  <Card.Text>
                    Manage staff schedules, permissions, and performance tracking.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 text-center">
                <Card.Img variant="top" src="https://source.unsplash.com/featured/?devices" alt="Multi-Platform Support" />
                <Card.Body>
                  <Card.Title>Multi-Platform Support</Card.Title>
                  <Card.Text>
                    Use POS on various devices including tablets, smartphones, and desktops.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <Card className="h-100 text-center">
                <Card.Img variant="top" src="https://source.unsplash.com/featured/?promotion" alt="Promotions and Discounts" />
                <Card.Body>
                  <Card.Title>Promotions & Discounts</Card.Title>
                  <Card.Text>
                    Create and manage promotional offers and discount codes to boost sales.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 text-center">
                <Card.Img variant="top" src="https://source.unsplash.com/featured/?reporting" alt="Reporting and Analytics" />
                <Card.Body>
                  <Card.Title>Reporting & Analytics</Card.Title>
                  <Card.Text>
                    Generate detailed reports to monitor performance and make informed decisions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 text-center">
                <Card.Img variant="top" src="https://source.unsplash.com/featured/?security" alt="Security Features" />
                <Card.Body>
                  <Card.Title>Security Features</Card.Title>
                  <Card.Text>
                    Advanced security protocols to protect your business and customer data.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default PointOfSale;
